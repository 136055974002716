    import PageHeader from "../../components/PageHeader";
    import "./privacyPolicy.css";
    import DocumentMeta from "react-document-meta";

    const meta = {
        title: "Amamit - iOS App - Privacy Policy",
        description: "Privacy Policy for the iOS App \"Amamit\""
    };
    const AmamitPrivacyPolicy = () => {
        return (
            <DocumentMeta {...meta}>
                <section className="about">
                    <PageHeader title="Amamit - Privacy Policy" />
                    <div className="aboutContainer container">
                        <div className="row">
                            <div className="contentContainer">
                                <h2>Introduction</h2>
                                <p>Welcome to Amamit, an app developed by Pierrejanineh.com. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data.</p>

                                <h2>Data Controller</h2>
                                <p>
                                    <strong>Pierrejanineh.com</strong><br />
                                    <strong>Contact Email:</strong> amamit@pierrejanineh.com
                                </p>

                                <h2>Information We Collect</h2>
                                <p>
                                    Amamit collects data that is not directly linked to individual users. The types of data we collect include:
                                </p>
                                <ul>
                                    <li><strong>Personal Information:</strong>  Age and city (if provided by the user).</li>
                                    <li><strong>Usage Data:</strong>  Collected through Firebase Analytics and Crashlytics for debugging and development purposes.</li>
                                </ul>

                                <h2>Purpose of Data Collection</h2>
                                <p>The data collected is used for the following purposes:</p>
                                <ul>
                                    <li>To improve the services and user experience of the app.</li>
                                    <li>To send notifications to users.</li>
                                    <li>For debugging and app development purposes.</li>
                                </ul>

                                <h2>Data Sharing</h2>
                                <p>The collected data is shared with Firebase. This data is not linked directly to individual users and is used in aggregate form to improve app functionality and performance.</p>

                                <h2>Data Retention</h2>
                                <p>Personal data (not directly linked to the user) is stored on Firebase Realtime Database indefinitely unless otherwise specified or required by law.</p>

                                <h2>Data Security</h2>
                                <p>We take the security of your data seriously. Data is stored on secure servers provided by Firebase Realtime Database.</p>

                                <h2>Third-Party Services</h2>
                                <p>Amamit uses the following third-party services:</p>
                                <ul>
                                    <li><strong>Firebase</strong> for data storage, analytics, and crash reporting.</li>
                                    <li><strong>AdMob</strong> for advertising purposes.</li>
                                </ul>

                                <h2>Age Restrictions</h2>
                                <p>Amamit is intended for users over the age of 17.</p>

                                <h2>Data Collection Methods</h2>
                                <p>Data is collected through user interactions with the app and automatically via Firebase Analytics and Crashlytics.</p>

                                <h2>Government Data Usage</h2>
                                <p>Amamit uses publicly available data from the Israeli government. This data is collected from their databases on a daily basis and is not manipulated in any way.</p>
                                <h3>Government Data Policy:</h3>
                                <ul>
                                    <li><strong>Source:</strong> Publicly available data from Data.Gov.il.</li>
                                    <li><strong>Usage Terms:</strong> Users are allowed to use the data in accordance with the terms provided by the Israeli government. The full policy can be found on their website in Hebrew.</li>
                                    <li><strong>Accuracy:</strong> We ensure that the data is collected accurately and presented as received from the government databases. We do not guarantee the ongoing availability of this data.</li>
                                    <li><strong>Disclaimers:</strong> Amamit does not represent or imply any official endorsement or affiliation with the Israeli government. All data is provided “as is” without any warranties or guarantees.</li>
                                </ul>

                                <h2>Effective Date</h2>
                                <p>This Privacy Policy is effective as of the launch date of the app.</p>

                                <h2>Changes to this Privacy Policy</h2>
                                <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page, and you are advised to review this Privacy Policy periodically for any updates.</p>

                                <h2>Contact Us</h2>
                                <p>
                                    If you have any questions about this Privacy Policy, please contact us at:<br />
                                    <strong>Email:</strong> amamit@pierrejanineh.com
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </DocumentMeta>
        );
    };

    export default AmamitPrivacyPolicy;
