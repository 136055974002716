import PageHeader from "../../components/PageHeader";
import "./privacyPolicy.css";
import DocumentMeta from "react-document-meta";

const meta = {
    title: "Loccali - iOS App - Privacy Policy | Developed by Pierre Janineh",
    description: "Privacy Policy for the iOS App \"Nikud\""
};
const LoccaliPrivacyPolicy = () => {
    return (
        <DocumentMeta {...meta}>
            <section className="about">
                <PageHeader title="Loccali - Privacy Policy" />
                <div className="aboutContainer container">
                    <div className="row">
                        <div className="contentContainer">
                            <p>This privacy policy outlines how Loccali handles user information. Please note that Loccali does not collect or store any personal information.</p>
                            <h2>Information Collection</h2>
                            <p>Loccali does not collect any personal information from its users.</p>
                            <h2>Information Usage</h2>
                            <p>Since no personal information is collected, Loccali does not use any user information.</p>
                            <h2>Information Sharing</h2>
                            <p>Loccali does not share any collected information with third parties as no information is collected.</p>
                            <h2>Cookies</h2>
                            <p>Loccali does not use cookies or similar technologies.</p>
                            <h2>Data Protection</h2>
                            <p>As Loccali does not collect any personal information, no data protection measures are required.</p>
                            <h2>User Rights</h2>
                            <p>Since Loccali does not collect any personal information, there are no user rights associated with data access, update, or deletion.</p>
                            <h2>Security</h2>
                            <p>Loccali does not collect or store any user information; therefore, no guarantees can be made regarding the security of user information.</p>
                            <p>If you have any questions or concerns about this privacy policy, please contact us at (support@pierrejanineh.com).</p>
                            <p>This privacy policy is effective as of 2023.</p>
                        </div>
                    </div>
                </div>
            </section>
        </DocumentMeta>
    );
};

export default LoccaliPrivacyPolicy;