export default class ItunesService {

    async getAppVersion(bundleIds) {
        try {
            const res = await fetch(`https://itunes-noisy-recipe-faad.janinehpierre.workers.dev/api?id=${bundleIds.join(',')}`);
            return res.json();
        } catch (e) {
            console.error(e);
            return bundleIds.map((bundleId) => ({version: null, trackId: bundleId}));
        }
    }
}
