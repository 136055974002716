import AboutMe from "../../components/AboutMe";
import PageHeader from "../../components/PageHeader";
import DocumentMeta from "react-document-meta";

const meta = {
    title: "About - Pierre Janineh | Mobile Developer | Crafting Innovative Digital Solutions",
    description: "Get to know Pierre up close."
};
const About = ({ name, brand, email, phone }) => {
    return (
        <DocumentMeta {...meta}>
            <section className="about">
                <PageHeader title="About me" /*description="Coming Soon..."*/ />
                <AboutMe name={name} brand={brand} email={email} phone={phone} />
            </section>
        </DocumentMeta>
    );
};

export default About;
