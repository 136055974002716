  import styled from 'styled-components';
  import aboutMeImg from "../images/aboutme.webp";
  import { motion } from "framer-motion";
  import SocialIcons from "../components/SocialIcons";
  import React, { useState, useEffect } from "react";
  // import { StyledLinkAsButton } from './styled/StyledLink';
  // import resume from "../pages/about/pierre-janineh-resume.pdf";

  const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--sm-spacing);
    margin: var(--sm-spacing) 0;
  
    @media (min-width: 750px) {
      justify-content: space-between;
    }
  `

  const AboutMe = ({ name, email, /*phone,*/ brand }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
      setDownloading(false);
    }, [downloading]);

    // const handleDownload = () => {
    //   setDownloading(true);
    //   const link = document.createElement("a");
    //   // link.href = resume;
    //   // link.download = "Michael-Yeates-Resume.pdf";
    //   link.onload = () => {
    //     link.remove();
    //     setDownloading(false);
    //   };
    //   document.body.appendChild(link);
    //   link.click();
    // };

    return (
        <div className="aboutContainer container">
          <div className="row">
            <motion.div
                className="personalImage col-12 col-lg-4"
                initial={{ y: "10vh", opacity: 0 }}
                animate={isLoaded ? { y: 0, opacity: 0.7 } : { y: "10vh", opacity: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <div className="snake">
                <img src={aboutMeImg} alt={name} onLoad={() => setIsLoaded(true)} />
              </div>
            </motion.div>
            <motion.div
                className="personalInfo col-12 col-lg-8"
                initial={{ y: "10vh", opacity: 0 }}
                animate={isLoaded ? { y: 0, opacity: 1 } : { y: "10vh", opacity: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
            >
              <div className="contentContainer">
                <h4>My Passion Is My Fashion</h4>
                <div className="contentDescription">
                  {brand}
                </div>
                <div className="infoContainer">
                  <div className="row">
                    <div className="col-12 col-md-6 info">
                      <span>Name:</span>
                      <p>Pierre Janineh</p>
                    </div>
                    <div className="col-12 col-md-6 info">
                      <span>Email:</span>
                      <p>
                        <a href={`mailto:${email}`}>{email}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <ButtonContainer>
                  {/*<StyledLinkAsButton onClick={handleDownload} disabled={downloading}>*/}
                  {/*  {downloading ? "Downloading..." : "Download Resume"}*/}
                  {/*</StyledLinkAsButton>*/}
                  <SocialIcons />
                </ButtonContainer>
              </div>
            </motion.div>
          </div>
        </div>
    );
  };

  export default AboutMe;
