import projectData from "./projectsData.json";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";
import DocumentMeta from "react-document-meta";
import React from "react";
import ItunesService from "../../services/itunes.service";

const Portfolio = () => {
    const ProjectList = () => {
        const [appVersions, setAppVersions] = React.useState(null);

        React.useEffect(() => {
            new ItunesService()
                .getAppVersion(projectData.map(({appStore}) => appStore))
                .then(setAppVersions);
        }, []);

        return projectData.map((project, i) => {
            return (
                <Project
                    key={i}
                    project={project}
                    appVersion={appVersions?.[i]?.version}
                />
            );
        });
    };

    const meta = {
        title: "Portfolio - Pierre Janineh | Projects and Apps Developed by Pierre Janineh",
        description: "Projects and Apps developed and created by Pierre with <3."
    };

    return (
        <DocumentMeta {...meta} >
            <section className="portfolio">
                <PageHeader title="Portfolio" /*description="View my work"*/ />
                <div className="row">
                    <ProjectList />
                </div>
            </section>
        </DocumentMeta>
    );
};

export default Portfolio;
