import {motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import Modal from "react-modal";
import React from "react";
import closeModal from "../images/close.svg";
import {StyledLink} from "./styled/StyledLink";

const modalStyle = {
    content: {
        backgroundColor: "#101010",
        color: "#dadada",
        padding: "60px",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "999",
        border: 'none'
    },
    overlay: {
        backgroundColor: 'rgb(0 0 0 / 30%)'
    }
}

const Project = ({project, appVersion}) => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const variants = {
        hidden: {x: project.id % 2 === 0 ? "10vw" : "-10vw", opacity: 0},
        visible: {x: 0, opacity: 1},
    };

    Modal.setAppElement("#root");

    const [showModal, setShowModal] = React.useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <motion.div
            ref={ref}
            className="col-sm-12 col-lg-6"
            variants={variants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            transition={{duration: 0.4, ease: "easeInOut"}}
        >
            <div
                style={{backgroundColor: project.bgcolor}}
                className="projectCard d-flex align-items-center justify-content-center p-5"
                onClick={handleOpenModal}
                data-open={showModal}
            >
                <div className="textWrap col-6 d-flex flex-column justify-content-center align-items-center m-5">
                    {appVersion && (
                        <p className="badge">
                            {appVersion}
                        </p>
                    )}
                    <p className="tech">
                        {project.technologies}
                    </p>
                    <h3 className="projectTitle">{project.title}</h3>
                    <span className="viewWork">More &#8594;</span>
                </div>
                <div className="imageContainer col-6 d-flex align-items-center justify-content-center">
                    <img src={project.image} alt="Laptop displaying the application"/>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                style={modalStyle}
                onRequestClose={handleCloseModal}
            >
                <img
                    src={closeModal}
                    className="closeMenu closeModal"
                    onClick={handleCloseModal}
                    alt="Close"
                ></img>
                <h3 className="modalTitle">{project.title}</h3>
                <p className="projectDescription">{project.description}</p>
                <StyledLink href={project.github} hidden={project.github === ""}>
                    View on GitHub
                </StyledLink>
                <StyledLink href={project.deployed} hidden={project.deployed === ""}>
                    Download on AppStore
                </StyledLink>
            </Modal>
        </motion.div>
    );
};

export default Project;
