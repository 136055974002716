import PageHeader from "../../components/PageHeader";
import "./privacyPolicy.css";
import DocumentMeta from "react-document-meta";

const meta = {
    title: "Miflas - iOS/tvOS/macOS/visionOS App - Privacy Policy",
    description: "Privacy Policy for \"Miflas\" App "
};
const MiflasPrivacyPolicy = () => {
    return (
        <DocumentMeta {...meta}>
            <section className="about">
                <PageHeader title="Miflas - Privacy Policy" />
                <div className="aboutContainer container">
                    <div className="row">
                        <div className="contentContainer">
                            <h2>Introduction</h2>
                            <p>Welcome to Miflas, an app developed by Pierrejanineh.com. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices regarding data collection and usage.</p>

                            <h2>Data Controller</h2>
                            <p>
                                <strong>Pierrejanineh.com</strong><br />
                                <strong>Contact Email:</strong> miflas@pierrejanineh.com
                            </p>

                            <h2>Information We Collect</h2>
                            <p>
                                Miflas does not collect any personal data from its users.
                            </p>

                            <h2>Purpose of Data Collection</h2>
                            <p>Since we do not collect any data, there are no purposes related to data collection.</p>

                            <h2>Data Sharing</h2>
                            <p>As Miflas does not collect any data, there is no data to share with third parties.</p>

                            <h2>Data Retention</h2>
                            <p>There is no personal data retention since no data is collected.</p>

                            <h2>Data Security</h2>
                            <p>We take the security of your interactions with Miflas seriously. As no data is collected, there are no data storage concerns.</p>

                            <h2>Third-Party Services</h2>
                            <p>Miflas does not use any third-party services that collect or process user data.</p>

                            <h2>Age Restrictions</h2>
                            <p>Miflas is intended for all users, regardless of age.</p>

                            <h2>Data Collection Methods</h2>
                            <p>No data is collected through user interactions with the app or any automated methods.</p>

                            <h2>Government Data Usage</h2>
                            <p>Miflas uses publicly available data from the Israeli government. This data is collected from their databases on a daily basis and is not manipulated in any way.</p>
                            <h3>Government Data Policy:</h3>
                            <ul>
                                <li><strong>Source:</strong> Publicly available data from Data.Gov.il.</li>
                                <li><strong>Usage Terms:</strong> Users are allowed to use the data in accordance with the terms provided by the Israeli government. The full policy can be found on their website in Hebrew.</li>
                                <li><strong>Accuracy:</strong> We ensure that the data is collected accurately and presented as received from the government databases. We do not guarantee the ongoing availability of this data.</li>
                                <li><strong>Disclaimers:</strong> Miflas does not represent or imply any official endorsement or affiliation with the Israeli government. All data is provided “as is” without any warranties or guarantees.</li>
                            </ul>

                            <h2>Effective Date</h2>
                            <p>This Privacy Policy is effective as of the launch date of the app.</p>

                            <h2>Changes to this Privacy Policy</h2>
                            <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page, and you are advised to review this Privacy Policy periodically for any updates.</p>

                            <h2>Contact Us</h2>
                            <p>
                                If you have any questions about this Privacy Policy, please contact us at:<br />
                                <strong>Email:</strong> miflas@pierrejanineh.com
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </DocumentMeta>
    );
};

export default MiflasPrivacyPolicy;
