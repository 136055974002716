import { motion } from "framer-motion";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import landingImage from "../../images/me2.webp";
import Draw from "../../components/Draw";
import SocialIcons from "../../components/SocialIcons";
import React, {useState} from "react";
import DocumentMeta from "react-document-meta";

const Container = styled.section`
    height: calc(100% - 93px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    letter-spacing: 1px;
    text-align: center;
    z-index: 1;
    color: #fff;
    text-shadow: 1px 1px 3px #000;

    //.name {
    //  color: #fff;
    //  font-weight: 700;
    //  margin-top: -100px;
    //  padding-bottom: 28px;
    //}
`;

const meta = {
    title: "Pierre Janineh | Mobile Developer | Crafting Innovative Digital Solutions",
    description: "Software Engineer and a Mobile Developer who loves creating solutions that enrich user experience."
};

const Landing = ({ name, /*tagline*/ }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    return (
        <DocumentMeta {...meta}>
            <Container className="landing">
                <TextContainer className="textContainer">
                    <h1 className="name">{name}</h1>
                    <div className="description">
                        <Typewriter
                            className="description"
                            onInit={(typewriter) => {
                                typewriter
                                    .changeDelay(35)
                                    .typeString("Software Engineering")
                                    .changeDelay(20)
                                    .pauseFor(1500)
                                    .deleteAll(20)
                                    .typeString("Envision. ")
                                    .pauseFor(500)
                                    .typeString("Code. ")
                                    .pauseFor(500)
                                    .typeString("Iterate.")

                                    .start();
                            }}
                        />
                    </div>
                </TextContainer>
                <div className="image-container">
                    <motion.img
                        className="landingImage"
                        initial={{ opacity: 0 }}
                        animate={isLoaded ? { opacity: 0.4 } : { opacity: 0 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        src={landingImage}
                        onLoad={() => setIsLoaded(true)}
                        alt="Pierre Janineh"
                    />
                </div>
                <Draw />
                <SocialIcons />
            </Container>
        </DocumentMeta>
    );
};

export default Landing;
