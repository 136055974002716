import styled from 'styled-components';
import PageHeader from "../../components/PageHeader";
import SocialIcons from "../../components/SocialIcons";
import Form from "../../components/Form";
import ContactInfo from "../../components/ContactInfo";
import DocumentMeta from "react-document-meta";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing);
    padding-bottom: var(--sm-spacing);

    @media (min-width: 750px) {
        .socialIcons::before,
        .socialIcons::after {
            background-color: var(--grey);
            content: "";
            height: 2px;
            width: var(--spacing);
            display: inline-block;
            margin: 0 10px 7px;
        }
    }
`;

const meta = {
    title: "Contact - Pierre Janineh | Mobile Developer | Crafting Innovative Digital Solutions",
    description: "Get in touch and let's have some coffee, I love coffee."
};

const Contact = ({ name, email, phone, discord }) => {
    return (
        <DocumentMeta {...meta}>
            <Container>
                <PageHeader title="Contact" description="Get in touch" />
                <div className="contactWrap container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <Form />
                        </div>
                        <div className="col-12 col-lg-6">
                            <ContactInfo
                                name={name}
                                email={email}
                                phone={phone}
                                discord={discord}
                            />
                        </div>
                    </div>
                </div>
                <SocialIcons />
            </Container>
        </DocumentMeta>
    );
};

export default Contact;
