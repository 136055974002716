import "./tag-name-buttons.css";

const TagNameButtons = ({ tags, filterByTag, tagsSelected }) => {
  return (
      <div className="tag-name-buttons-container">
        {tags.map((tagName) => {
          const isSelected = tagsSelected.includes(tagName);
          return (
              <div key={`Filter_By_${tagName}`} className="tag-name">
                <input
                    id={tagName}
                    type="checkbox"
                    name="tagName"
                    onChange={(e) => {
                      filterByTag(tagName, e.target.checked);
                    }}
                    className="tag-name-checkbox"
                    value={isSelected}
                />
                <label htmlFor={tagName} className="tag-name-label">
                  {tagName}
                </label>
              </div>
          );
        })}
      </div>
  );
};

export default TagNameButtons;
