import styled from "styled-components";
import {Link} from "react-router-dom";

export const StyledLink = styled.a`
    color: #f9f9f9;
    background-color: var(--hl-color);
    border: none;
    font-size: 14px;
    padding: 12px var(--sm-spacing);
    border-radius: 20px;
    text-decoration: none;
    margin-top: 10px;
    text-align: center;

    &:hover {
        background-color: #03b979;
    }
`;
export const StyledLinkAsButton = styled(StyledLink).attrs({
    as: 'button',
})`
    cursor: pointer;
`;

export const StyledLinkAsRouterLink = styled(StyledLink).attrs({
    as: Link
})``;
