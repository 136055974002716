import React from "react";
import {Routes, Route, useLocation, Navigate} from "react-router-dom";

import Gallery from '../pages/gallery/Gallery';
import Landing from "../pages/landing/Landing";
import About from "../pages/about/About";
import Portfolio from "../pages/portfolio/Portfolio";
import Contact from "../pages/contact/Contact";
import PageNotFound from "../pages/404/PageNotFound";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import LoccaliPrivacyPolicy from "../pages/privacyPolicy/LoccaliPrivacyPolicy";
import AmamitPrivacyPolicy from "../pages/privacyPolicy/AmamitPrivacyPolicy";
import MiflasPrivacyPolicy from "../pages/privacyPolicy/MiflasPrivacyPolicy";

const AnimatedRoutes = ({ personalDetails }) => {
    const location = useLocation();
    return (
        <Routes location={location}>
            <Route path="/" element={<Landing name={personalDetails.name} tagline={personalDetails.tagline} />} />
            <Route
                path="/about"
                element={
                    <About
                        name={personalDetails.name}
                        email={personalDetails.email}
                        brand={personalDetails.brand}
                        phone={personalDetails.phone}
                    />
                }
            />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/storage-analysis/privacy-policy" element={<PrivacyPolicy storage={true}/>} />
            <Route path="/nikud/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/loccali/privacy-policy" element={<LoccaliPrivacyPolicy />} />
            <Route path="/amamit/privacy-policy" element={<AmamitPrivacyPolicy />} />
            <Route path="/miflas/privacy-policy" element={<MiflasPrivacyPolicy />} />
            <Route
                path="/contact"
                element={
                    <Contact
                        name={personalDetails.name}
                        email={personalDetails.email}
                        phone={personalDetails.phone}
                        discrod={personalDetails.discord}
                    />
                }
            />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
    );
};

export default AnimatedRoutes;
