import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobile,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {faDiscord} from "@fortawesome/free-brands-svg-icons/faDiscord";

const InfoIcon = styled.span`
  color: var(--hl-color);
  width: var(--spacing);
  display: flex;
  padding-right: var(--sm-spacing);
  justify-content: center;
  align-items: center;
  svg {
    min-width: 28px;
    min-height: 26px;
  }
}`

const ContactInfo = ({ name, email, phone }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
      <motion.div
          className="contactInfo"
          ref={ref}
          initial={{ x: "10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <h4 className="contentTitle">Contact Information</h4>
        <p className="infoDescription">
          Open for opportunities. Let's connect and build something awesome
          together!{" "}
        </p>
        <ul className="listInfo">
          <li>
            <div className="personalContactInfo">
              <InfoIcon>
                <FontAwesomeIcon icon={faUser} />
              </InfoIcon>
              <div className="mediaWrap">
                <h6 className="infoType">Name</h6>
                <span className="infoValue">{name}</span>
              </div>
            </div>
          </li>
          <li>
            <div className="personalContactInfo">
              <InfoIcon>
                <FontAwesomeIcon icon={faMobile} />
              </InfoIcon>
              <div className="mediaWrap">
                <h6 className="infoType">Phone</h6>
                <span className="infoValue">{phone}</span>
              </div>
            </div>
          </li>
          <li>
            <div className="personalContactInfo">
              <InfoIcon>
                <FontAwesomeIcon icon={faEnvelope} />
              </InfoIcon>
              <div className="mediaWrap">
                <h6 className="infoType">Email</h6>
                <span className="infoValue">
                <a href={`mailto:${email}`}>{email}</a>
              </span>
              </div>
            </div>
          </li>
          <li>
            <div className="personalContactInfo">
              <InfoIcon>
                <FontAwesomeIcon icon={faDiscord} />
              </InfoIcon>
              <div className="mediaWrap">
                <h6 className="infoType">Discord</h6>
                <span className="infoValue">
                <a href="https://discord.gg/ArVMxDC5XG">pierrejanineh.com</a>
              </span>
              </div>
            </div>
          </li>
        </ul>
      </motion.div>
  );
};

export default ContactInfo;
