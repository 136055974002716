import React from "react";
import "./gallery-image.css";

const GalleryImage = ({ photo, wrapperStyle, renderDefaultPhoto }) => {
  return (
      <div className="gallery-image" style={wrapperStyle} /*TODO: Add Image Titles | data-image-title={photo['data-image-title']}*/>
        {renderDefaultPhoto({
          wrapped: true,
          ...photo,
        })}
      </div>
  );
};

export default GalleryImage;
