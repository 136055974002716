import React from "react";
import PhotoAlbum from "react-photo-album";
import imagePaths from "../../lib/utils/imagePaths";
import Lightbox from "yet-another-react-lightbox";
import GalleryImage from "./GalleryImage/GalleryImage";
import TagNameButtons from "./GalleryActions/TagNameButtons/TagNameButtons";
import "yet-another-react-lightbox/styles.css";
import "./gallery.css";
import DocumentMeta from "react-document-meta";

const Gallery = () => {
    const [tagsSelected, setTagsSelected] = React.useState([]);
    const [lightboxIndex, setLightboxIndex] = React.useState(-1);
    const [filteredImages, setFilteredImages] = React.useState([]);

    function filterByTag(filterTag, selected) {
        setTagsSelected((prev) => {
            const newTagsSelected = selected
                ? [...prev, filterTag]
                : prev.filter((tag) => filterTag !== tag);

            return newTagsSelected;
        });
    }

    function openLightbox({ index }) {
        setLightboxIndex(index);
    }

    React.useEffect(() => {
        const newImagePaths = (
            !tagsSelected.length
                ? Object.values(imagePaths)
                : tagsSelected.map((tag) => imagePaths[tag])
        )
            .flat()
            .sort((a, b) => 0.5 - Math.random());
        setFilteredImages(newImagePaths);
    }, [tagsSelected]);

    const meta = {
        title: "Photo Gallery | Photos by Pierre Janineh",
        description: "Pierre is also a hobbyist photographer. Here is a bit of his work."
    };

    return (
        <DocumentMeta {...meta}>
            <div className="gallery">
                {filteredImages.length}
                <div className="gallery-actions">
                    <TagNameButtons
                        filterByTag={filterByTag}
                        tags={["Animals", "Food", "Nature", "Outside", "Portraits"]}
                        tagsSelected={tagsSelected}
                    />
                </div>
                <PhotoAlbum
                    layout="rows"
                    photos={filteredImages}
                    spacing={0}
                    renderPhoto={(props) => <GalleryImage {...props} />}
                    onClick={(imagePath) => openLightbox(imagePath)}
                    columns={(width) =>
                        width >= 1920 ? 4 : width >= 1080 ? 3 : width >= 640 ? 2 : 1
                    }
                />
                <Lightbox
                    animation={"fade"}
                    index={lightboxIndex}
                    close={() => setLightboxIndex(-1)}
                    slides={filteredImages}
                    open={lightboxIndex !== -1}
                />
            </div>
        </DocumentMeta>
    );
};

export default Gallery;
