import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faLinkedin,
    faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { faDiscord, faAngellist } from "@fortawesome/free-brands-svg-icons";

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const IconLink = styled.a`
    text-decoration: none;
    transition: color 0.2s ease-in, transform 0.2s ease-in;
    color: var(--text-color);
    padding: 10px;
    &:hover {
        transform: scale(1.2);
        color: var(--hl-color);
    }
    svg {
        min-height: 24px;
    }
    path {
        transition: fill 0.2s ease-in;
        fill: currentColor;
    }
`;

const SocialIcons = () => {
    const iconProps = (platform) => ({
        "aria-hidden": "true",
        "aria-label": platform + " Profile link",
        title: `Pierre Janineh's ${platform} Profile`,
    });

    return (
        <Container className="socialIcons">
            <IconLink href="https://github.com/pierrejanineh">
                <FontAwesomeIcon icon={faGithub} {...iconProps("Github")} />
            </IconLink>
            <IconLink href="https://www.linkedin.com/in/pierrejanineh/">
                <FontAwesomeIcon icon={faLinkedin} {...iconProps("Linkedin")} />
            </IconLink>
            <IconLink href="https://medium.com/programming-with-pierre">
                <FontAwesomeIcon icon={faMedium} {...iconProps("Medium")} />
            </IconLink>
            <IconLink href="https://discord.gg/ArVMxDC5XG">
                <FontAwesomeIcon icon={faDiscord} {...iconProps("Discord")} />
            </IconLink>
            <IconLink href="https://wellfound.com/u/pierrejanineh">
                <FontAwesomeIcon icon={faAngellist} {...iconProps("wellfound")} />
            </IconLink>
        </Container>
    );
};

export default SocialIcons;
